<template>
  <div class="partner">
    <TitleSection :title="$t('PartnerTitle')" :TextAlign="`center`" />
    <swiper
      class="swiper pb-4"
      v-if="Partners.length > 0"
      :speed="8000"
      :loop="true"
      :autoplay="{
        delay: 0,
        disableOnInteraction: false,
        reverseDirection: true,
      }"
      :breakpoints="swiperOptions.breakpoints"
    >
      <swiper-slide
        class="item"
        v-for="item in Partners.slice(0, Partners.length / 2)"
        :key="item.id"
      >
        <a :href="item.website_url" target="_blank">
          <!-- <img class="w-100" @error="onImageLoadFailure($event)" :src="item.image" alt=""> -->
          <img :src="item.image" alt="partner" loading="lazy" />
        </a>
      </swiper-slide>
    </swiper>
    <swiper
      class="swiper"
      :space-between="20"
      v-if="Partners.length > 0"
      :speed="8000"
      :loop="true"
      :autoplay="{
        delay: 0,
        disableOnInteraction: false,
      }"
      :breakpoints="swiperOptions.breakpoints"
    >
      <swiper-slide
        class="item"
        v-for="item in Partners.slice(Partners.length / 2, Partners.length)"
        :key="item.id"
      >
        <a :href="item.website_url" target="_blank">
          <!-- <img :src="item.image" alt="partner" /> -->
          <VLazyImage  :src="item.image" alt="partner"/>
        </a>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
// Import Swiper Vue.js components
export default {
  data() {
    return {
      swiperOptions: {
        breakpoints: {
          300: {
            // when window width from 300px to 576px
            slidesPerView: 2,
            spaceBetween: 10,
          },
          576: {
            // when window width from 576px to 767px
            slidesPerView: 2,
            spaceBetween: 20,
          },
          767: {
            // when window width from 767px to 991px
            slidesPerView: 3,
            spaceBetween: 20,
          },

          991: {
            // when window width from 991px to 1200px
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1200: {
            // when window width from 1200px to higher
            slidesPerView: 5,
            spaceBetween: 20,
          },
        },
      },
    };
  },
  components: {
    TitleSection: defineAsyncComponent(() =>
      import("@/components/Global/TitleSection.vue")
    ),
  },
  methods: {
    //   onImageLoadFailure (event) {
    //     event.target.src = '@/assets/img/global/Logo1.svg'
    //   }
  },
  computed: {
    Partners() {
      return this.$store.state.Partners;
    },
  },
  mounted() {
    this.$store.dispatch("GetPartners");
  },
};
</script>
<style scoped>
.swiper-slide img {
  max-width: 98%;
}
img {
  border: 1px #0349A8 solid !important;
  border-radius: 10px !important;
  color: #0349A8;
}
</style>
